import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '../resources/i18n';

i18n.use(initReactI18next).init({
  resources,
  lng: 'ru',
  fallbackLng: 'ru',
  defaultNS: 'global',
  react: {
    wait: true
  }
});

export default i18n;
