import * as React from 'react'

const url = window.location.pathname.split('/');
const orderCode = url[url.length - 1];

const initialState = {
  orderCode,
  locker: {},
  rating: null,
  type: 'new',
  parcelInfo: {},
  loading: false
}

const StoreContext = React.createContext()

function storeReducer(state, action) {
  switch (action.type) {
    case 'SET_INFO':
      return { ...state, ...action.payload }
    case 'SET_TYPE':
      return { ...state, type: action.payload }
    case 'SET_PARCEL_INFO':
      return { ...state, parcelInfo: action.payload }
    case 'LOADING':
      return { ...state, loading: action.payload }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function StoreProvider({ children }) {
  const [state, dispatch] = React.useReducer(storeReducer, { ...initialState })
  const value = {state, dispatch}
  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

function useStore() {
  const context = React.useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider')
  }
  return context
}

export { StoreProvider, useStore }
